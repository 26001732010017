* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.CheckBox > div {
  border: 1px solid #d9dcdd;
  width: 24px;
  height: 24px;
}
.CheckBox > div > svg {
  stroke: #525b64;
}

@font-face {
  font-family: 'Lucida Grande';
  src: url(./fonts/LucidaGrande/LucidaGrande.ttf) format('truetype');
}

@font-face {
  font-family: 'Lucida Grande';
  src: url(./fonts/LucidaGrande/LucidaGrandeBold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Helvetica';
  src: url(./fonts/Helvetica/Helvetica.ttf) format('truetype');
}

@font-face {
  font-family: "Helvetica";
  src: url(./fonts/Helvetica/Helvetica-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Lato';
  font-weight: normal;
  src: url(./fonts/Lato/Lato-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-weight: bold;
    src: url(./fonts/Lato/Lato-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Lato';
    font-weight: black;
    src: url(./fonts/Lato/Lato-Black.ttf) format('truetype');
}